import { ProductCollection } from "@medusajs/medusa"
import { useEffect, useState } from "react"
import { medusaClient } from "./config"

interface ListCollectionsHookResult {
    collections: ProductCollection[]
    loading: boolean
    error: Error | null
}

export const useCollections = (): ListCollectionsHookResult => {
    const [collections, setCollections] = useState<ProductCollection[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const fetchData = async () => {
          return await medusaClient.collections.list()
          .then(res => setCollections(res.collections))
          .catch(err => setError(err))
          .finally(() => setLoading(false))
        }
        
        fetchData()
    }, [])
    
    return {
        collections,
        loading,
        error
    }
}