"use client"

import Image from "next/image";
import Link from "next/link";
import Logo from "../../../public/images/logo.png";
import WhiteLogo from "../../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../lib/daynamicNavigation";
import { useEffect, useMemo, useState } from "react";
import { usePathname } from "next/navigation";
import { socialIcons } from "../../data/footer_data";
import { useFeaturedProduct } from "@lib/use-featured-product-hook"
import { TrackedLink } from "@lib/analytics";
import { useCollections } from "@lib/use-collections";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState<string | null>(null);
  const [isScroll, setScroll] = useState(false);

  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const currentPath = usePathname();
  const {
    featuredProduct: { featuredProduct, until },
    loading: loadingFeaturedProduct,
    error,
  } = useFeaturedProduct()

  const { collections, loading: loadingCollections } = useCollections()
  /* -------------------------------------------------------------------------- */
  /*                            dynamic navigations                             */
  /* -------------------------------------------------------------------------- */
  
  const pages = useMemo(() => [
    {
        id: uuidv4(),
        name: "בית",
        path: "/",
        trackingProps: { to: "home", component: "header" }
    },
    {
        id: uuidv4(),
        name: "היצירה היומית",
        path: loadingFeaturedProduct ? "/#hero" : ((featuredProduct) ? `/product/${featuredProduct.handle}` : "/#hero"),
        trackingProps: { to: loadingFeaturedProduct ? "main_page_hero" : ((featuredProduct) ? `featured_product_page` : "main_page_hero"), component: "header", product_id: featuredProduct?.id }
    },
    {
      id: uuidv4(),
      name: "היצירות שלי",
      pages: loadingCollections ? [] : collections?.map(collection => {
        return {
          id: collection.id,
          name: collection.title,
          path: `/collection/${collection.handle}`,
          trackingProps: { to: collection.handle, component: "header" }
        }
      })
    },
    {
        id: uuidv4(),
        name: "אודות",
        path: "/about",
        trackingProps: { to: "about", component: "header" }
    },
  ], [loadingCollections, collections])

  const mobileCollapse = (id: string) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  const headerTopLevelSharedClasses = `flex items-center px-5 py-3.5 justify-between focus:text-accent hover:text-accent dark:text-white w-full`

  return <>
    {/* main desktop menu start*/}
    <header
      className={`js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors ${
        isScroll ? "js-page-header--is-sticky" : ""
      }`}
    >
      <div className="flex items-center px-6 py-6 xl:px-24 ">
        {/* <Link className="shrink-0" href="/" >

          <div className={`hidden lg:visible text-xl text-jacarta-700 ${isScroll ? "opacity-0" : ""}`}>
            Craft Every Day
            <Image
              src={Logo}
              height={28}
              width={130}
              alt="Xhibiter | NFT Marketplace"
              className="max-h-7 h-auto "
            />
          </div>
          <div className="hidden dark:block">
            Craft Every Day
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt="Xhibiter | NFT Marketplace"
            />
          </div>

        </Link> */}
        {/* End  logo */}

        <div className="dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent w-full">
          <nav className="navbar w-full mt-24 lg:mt-0">
            <ul className="flex flex-col justify-center lg:flex-row">
                {
                    pages.map(({ id, name, path, trackingProps, pages: subpages }) => {
                        return (
                            <li className={subpages && subpages.length > 0 ? `js-nav-dropdown group relative` : ``} key={id}>
                                { path ? (
                                  <TrackedLink trackingProps={trackingProps} href={path} className={headerTopLevelSharedClasses}>
                                    <span
                                    className={`text-md ${
                                        isChildrenPageActive(path, currentPath)
                                        ? "text-accent dark:text-accent"
                                        : "text-jacarta-700"
                                    } `}
                                    >
                                      {name}
                                    </span>
                                    {/* <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                                        new
                                    </span> */}
                                </TrackedLink> ) : (
                                  <button className={`dropdown-toggle ${headerTopLevelSharedClasses}`}>
                                    <span
                                      className={`text-md ${
                                        isParentPageActive(subpages, currentPath)
                                          ? "text-accent dark:text-accent"
                                        : "text-jacarta-700"
                                      }`}
                                    >
                                      {name}
                                    </span>
                                  </button>
                                ) }

                              {/* Subpages */}
                              <ul
                                className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                                aria-labelledby="navDropdown-4"
                              >
                                {subpages?.map?.(({ id, name, path, trackingProps }) => (
                                  <li key={id}>
                                    <TrackedLink
                                      trackingProps={trackingProps}
                                      href={path}
                                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                                      >

                                      <span
                                        className={`text-sm ${
                                          isChildrenPageActive(path, currentPath)
                                            ? "text-accent dark:text-accent"
                                            : "text-jacarta-700"
                                        }`}
                                      >
                                        {name}
                                      </span>

                                    </TrackedLink>
                                  </li>
                                ))}
                              </ul>
                            </li>
                        )
                    })
                }
            </ul>
          </nav>
          {/* End menu for desktop */}
        </div>
        {/* header menu conent end for desktop */}

        <div className="lg:hidden">
          <button
            className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            aria-label="open mobile menu"
            onClick={() => setToggle(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
            </svg>
          </button>
        </div>
        {/* End mobile toggle button */}
      </div>
    </header>
    {/* main desktop menu end */}

    {/* start mobile menu and it's other materials  */}
    <div
      className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
        toggle ? "nav-menu--is-open" : "hidden"
      }`}
    >
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">

        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => setToggle(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      {/* mobile menu top header content */}

      {/* <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
        <input
          type="search"
          className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
          placeholder="Search"
        />
        <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-500 h-4 w-4 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
          </svg>
        </span>
      </form> */}
      {/* End search form mobile menu  */}

      <nav className="navbar w-full mt-24">
        <ul className="flex flex-col lg:flex-row">
            {pages.map(({ id, name, path, pages: subpages }) => {
                return (
                <li key={id} className={`${subpages && subpages.length > 0 ? 'js-nav-dropdown' : ''} py-3.5 font-semibold group relative`} onClick={() => setToggle(false)}>
                    {path ? (
                      <TrackedLink href={path} className={`flex items-center justify-between w-full ${isChildrenPageActive(path, currentPath) ? "text-accent dark:text-accent" : "text-jacarta-700 dark:text-white"}`}>
                        {name}
                      </TrackedLink>) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up
                            mobileCollapse(id);
                          }}
                          className="dropdown-toggle text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between dark:text-white lg:px-5 w-full"
                        >
                        <span className={`${isParentPageActive(subpages, currentPath) ? "text-accent dark:text-accent" : "text-jacarta-700 dark:text-white"}`}>
                          {name}
                        </span>
                        <i className="lg:hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            className="h-4 w-4 dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        </i>
                      </button>)}

                      <ul
                        className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                          isCollapse === id ? "block" : "hidden"
                        }`}
                        aria-labelledby="navDropdown-4"
                      >
                        {subpages?.map(({ id, path, name, trackingProps }) => (
                          <li key={id} onClick={() => setToggle(false)}>
                            <TrackedLink
                              trackingProps={trackingProps}
                              href={path}
                              className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                              >
                                <span className={`${isChildrenPageActive(path, currentPath) ? "text-accent dark:text-accent" : "text-jacarta-700 dark:text-white"}`}>
                                  {name}
                                </span>
                            </TrackedLink>
                          </li>
                        ))}
                      </ul>
                </li>)
            })}
        </ul>
      </nav>
      {/* End navbar mobile menu  */}

      <div className="w-full lg:hidden">
        <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0" />
        <div className="flex items-center justify-center gap-x-5">
        {socialIcons.map(({ id, href, text}) => {
                  return (
                    <Link
                      href={href}
                      key={id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <svg role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon={text}
                            className={`icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white`}>
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </Link>
                  );
                })}
        </div>
      </div>
      {/* mt-10 w-full lg:hidden */}
    </div>
    {/* End mobile menu and it's other materials */}
  </>;
}
