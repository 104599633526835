"use client";

import { Dispatch, SetStateAction, createContext, useContext, useState } from "react"

interface ContextProps {
    sidebarOpen: boolean
    setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

const AppContext = createContext<ContextProps>({
    sidebarOpen: false,
    setSidebarOpen: (): boolean => false,
})

export default function AppProvider({ children }: { children: React.ReactNode }) {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

    return (
        <AppContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppProvider = () => useContext(AppContext)
