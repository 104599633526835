const footerMenuList = [
  {
    id: 1,
    title: "דפים",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/",
        text: "ראשי",
      },
      {
        id: 2,
        href: "/#featured",
        text: "היצירה היומית",
      },
      {
        id: 3,
        href: "/about",
        text: "אודות",
      }
    ],
  }
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/profile.php?id=61558087439017",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://instagram.com/craft.every.day",
    text: "instagram",
  },
  {
    id: 3,
    href: "https://www.tiktok.com",
    text: "tiktok",
  }
];

export { footerMenuList, socialIcons };
